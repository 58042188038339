import { Stack } from '@mui/joy';
import { useEffect, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import GraphLayout from 'components/GraphLayout';
import { delay } from 'util/delay';

const { auth } = require('remote/firebase');

const Detail = () => {

	const { targetID } = useParams();  // Destructure the user_id from useParams
	console.log(targetID)
	// const [searchParams, _] = useSearchParams();
	const [personInformation, setPersonInformation] = useState({});
	const [loading, setLoading] = useState(true);

	const [nodeCount, setNodeCount] = useState(0);

	const user = auth.currentUser;

	function transformData(data) {
		const nodes = [];
		const links = [];
	
		// Create a map to track unique nodes by UUID
		const nodeMap = new Map();
	
		// Helper function to add a node if it doesn't exist yet
		function addNode(type, uuid, properties) {
		if (!nodeMap.has(uuid)) {
			const node = {
			id: uuid, // Use the uuid as the node's id
			type: type, // Node type (Person, Company, etc.)
			properties: properties, // Node properties (name, title, etc.)
			};
			nodes.push(node);
			nodeMap.set(uuid, node);

			setNodeCount(nodes.length);
		}
		}
	
		// Helper function to create links between nodes
		function addLink(source, target, relationship) {
		links.push({
			source,
			target,
			relationship, // You can use this to store the type of relationship (e.g., 'WORKS_FOR', 'POSTED')
		});
		}
	
		// Add the Person node
		const personUUID = data.uuid;
		addNode('Person', personUUID, {
		name: data.name,
		linkedInURL: data.linkedInURL,
		email: data.email,
		photoURL: data.photoURL,
		headline: data.headline,
		summary: data.summary,
		});

		data.taggedWithTag.forEach(tag => {
		addNode('Tag', tag.uuid, {
			tag: tag.tag,
		});
		addLink(personUUID, tag.uuid, 'TAGGED_WITH');
		})

		data.talksAboutTopic.forEach(topic => {
		addNode('Topic', topic.uuid, {
			topic: topic.topic,
		});
		addLink(personUUID, topic.uuid, 'TALKS_ABOUT'); // Link between Person and Location
		});

		
		data.hasPainPointPainPoint.forEach(painpoint => {
		addNode('PainPoint', painpoint.uuid, {
			pain: painpoint.pain,
		});
		addLink(personUUID, painpoint.uuid, 'HAS_PAIN'); // Link between Person and Location
		});

		data.hasIntentIntent.forEach(intent => {
		addNode('Intent', intent.uuid, {
			action: intent.action,
		});
		addLink(personUUID, intent.uuid, 'HAS_INTENT'); // Link between Person and Location
		});

		data.skilledInSkill.forEach(skill => {
		addNode('Skill', skill.uuid, {
			skill: skill.title
		});
		addLink(personUUID, skill.uuid, 'HAS_SKILL'); // Link between Person and Location
		});
	
		// Add Companies worked for
		data.workedForCompany.forEach(company => {
		addNode('Company', company.uuid, {
			name: company.name,
			linkedinID: company.linkedinID,
		});
		addLink(personUUID, company.uuid, 'WORKED_FOR'); // Link between Person and Company
		
		company.headquartersAtLocation.forEach(location => {
			addNode('Location', location.uuid, {
			address: `${location.city}, ${location.country}`,
			});
			addLink(company.uuid, location.uuid, 'HEADQUARTERS_IN');
		})

		company.taggedWithTag.forEach(tag => {
			addNode('Tag', tag.uuid, {
			tag: tag.tag,
			});
			addLink(company.uuid, tag.uuid, 'TAGGED_WITH');
		})

		company.articlesWrittenAboutCompany.forEach(article => {
			addNode('Article', article.uuid, {
			headline: article.headline,
			});
			addLink(article.uuid, company.uuid, 'ARTICLE_ABOUT');
		})

		company.operatesInIndustry.forEach(industry => {
			addNode('Industry', industry.uuid, {
			industry: industry.industry,
			});
			addLink(company.uuid, industry.uuid, 'OPERATES_IN_INDUSTRY');
		})

		company.specialisesInSpecialty.forEach(specialty => {
			addNode('Specialty', specialty.uuid, {
			specialty: specialty.specialty,
			});
			addLink(company.uuid, specialty.uuid, 'SPECIALISES_IN');
		})
		
		if (company.employeeCount) {
			addNode('EmployeeCount', company.employeeCount, {
				count: `${company.employeeCount} employees`
			});
			addLink(company.uuid, company.employeeCount, 'HAS_NUM_EMPLOYEES');
		}
		});
	
		// Add Posts
		// data.posted_posts.forEach(post => {
		//   addNode('Post', post.uuid, {
		//     content: post.content,
		//     date: post.date,
		//   });
		//   addLink(personUUID, post.uuid, 'POSTED'); // Link between Person and Post
		// });
	
		// Add Location where Person lives
		data.livesInLocation.forEach(location => {
		addNode('Location', location.uuid, {
			address: `${location.city}, ${location.country}`
		});
		addLink(personUUID, location.uuid, 'LIVES_IN'); // Link between Person and Location
		});
	
		// Add Schools the person attended
		data.studiedAtSchool.forEach(school => {
		addNode('School', school.uuid, {
			name: school.name,
		});
		addLink(personUUID, school.uuid, 'STUDIED_AT'); // Link between Person and School
		
		if (school.location) {
			addNode('Location', school.location.uuid, {
			address: `${school.location.city}, ${school.location.country}`
			});
			addLink(school.uuid, school.location.uuid, 'LOCATED_IN');
		}
		});
	
		return { nodes, links };
	}

	const makeGetRequestForTarget = async (targetID, token) => {
		// const response = await fetch(`http://localhost:8000/api/person/${targetID}?depth=2`, {
		const response = await fetch(`https://two-degrees.net/api/person/${targetID}?depth=2`, {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`,
			}
		});

		return response
	}

	const requestScrapeForTarget = async (userFirebaseID, targetUUID) => {
		console.log({
			user_firebase_id: userFirebaseID,
			target_uuid: targetUUID
		})

		// const response = await fetch(`http://localhost:8001/profile-detail/`, {
		const response = await fetch(`https://two-degrees.net/api/augment/profile-detail/`, {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
			},
			body: JSON.stringify({
				user_firebase_id: userFirebaseID,
				target_uuid: targetUUID
			})
		});

		if (response.status === 202) {
			console.log("Request to scrape target successful")
			var data = await response.json();
			console.log(data)
		}
		else {
			console.log(`Request to scrape target FAILED. Status: ${response.status}`)
		}
	}
	
	const getUserDetails = useCallback(async (targetID) => {
		setLoading(true);
		const token = await user.getIdToken();

		console.log({
		    'Authorization': `Bearer ${token}`,
		});

		var response = await makeGetRequestForTarget(targetID, token)
		console.log(response);

		if (response.status === 200) {
			var data = await response.json();
			console.log(data);

			// TODO: we need to look into how often a user gets scraped
			if (data.dateLastScraped === null) {
				requestScrapeForTarget(user.uid, targetID)

				do {
					console.log(`We couldn't find a value for data.dateLastScraped (value = ${data.dateLastScraped}). Requested new scrape!`);
					await delay(3000);
					
					response = await makeGetRequestForTarget(targetID, token)
					if (response.status === 200) {
						data = await response.json();
						console.log(data)
					}
					else
						console.log(response.status)
				} while (data.dateLastScraped === null)
			}

			setPersonInformation(transformData(data));
		} else {
			// TODO: error snackbar
			console.log("We messed up here pal. Sorry!");
		}
		setLoading(false);
	}, [user]); // Add 'user' as a dependency because you're using 'user' inside the callback

	useEffect(() => {
		if (targetID) {
			getUserDetails(targetID);
		}
	}, [targetID, getUserDetails]);

	const [outreachMessage, setOutreachMessage] = useState("")
	const generateOutreachMessage = () => {
		alert("Sorry, this is a premium feature. Email sales@two-degrees.net for more information on purchasing.");
	}

	return (
	<div>
		{loading ? <h3>Loading... (this might take a minute!)</h3> :
			<Stack>
				<h3>User Detail</h3>
				<input value={outreachMessage} onChange={(e) => setOutreachMessage(e.target.value)} placeholder={`Hi ${personInformation.nodes[0].properties.name.split(" ")[0]}...`} min/>
				<Stack direction={"row"} spacing={3}>
				<button onClick={generateOutreachMessage}>Click to generate template message</button>
				<button disabled>Send message via LinkedIn</button>
				<button disabled>Send message via email</button>
				</Stack>
				<p>Showing {nodeCount}/{Math.floor(nodeCount * Math.E)} nodes (free trial). Sign up to premium by emailing sales@two-degrees.net and get access to all information.
				</p>
				<GraphLayout graphData={personInformation}/>
			</Stack>
		}
	</div>
	);
}

export default Detail