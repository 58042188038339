const { useState, useEffect, useCallback } = require("react");
const { useSearchParams, Link } = require("react-router-dom");

const { auth } = require('remote/firebase');

const Results = () => {
    const [searchParams] = useSearchParams();
    const [searchResults, setSearchResults] = useState({ people: [] });
    const [loading, setLoading] = useState(true);

    const user = auth.currentUser;

    const performSearch = useCallback(async (query) => {
        const token = await user.getIdToken();
        setLoading(true);

        console.log({
            // 'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        });

        // const response = await fetch(`http://localhost:8002/api/search?q=${query}&uid=${user.uid}`, {
        const response = await fetch(`https://two-degrees.net/api/search?q=${query}&uid=${user.uid}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        });

        console.log(response)
        if (response.status === 200) {
            const data = await response.json();
            console.log(data)
            setSearchResults(data.results || { people: [] });
        }
        else {
            // TODO: error snackbar
            console.log("We fucked up here pal. Sorry!")
        }
        setLoading(false);
    }, [user]);

    useEffect(() => {
        performSearch(searchParams.get("q"));
    }, [searchParams, performSearch]);

    return (
        <div>
            {loading ? 
                <h3>Loading...</h3> : 
                <>
                    {searchResults && searchResults.length > 0 ? (
                        <table border="1">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Headline</th>
                                    <th>Terms</th>
                                </tr>
                            </thead>
                            <tbody>
                                {searchResults.map((person, index) => (
                                    <tr key={index}>
                                        <td><Link to={`/detail/${person.uuid}`}>{person.name}</Link></td>
                                        <td>{person.headline}</td>
                                        <td>{person.tags}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <p>No results found. Click <Link to="/">here</Link> to search again.</p>
                    )}
                </>
            }
        </div>
    );
};

export default Results;