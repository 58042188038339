import SearchBar from './SearchBar.js';
import 'assets/search.scss';

import { validateAccountInitialised } from 'remote/auth.js'
import { useNavigate } from 'react-router-dom';

const Search = () => {
	const navigate = useNavigate()
	
	validateAccountInitialised()
		.then((accountInitialised) => {
			if(!accountInitialised)
				// console.log("account not init, but we don't care (today (12th Sep 2024)).")
				navigate("/connect-linkedin")
		})

	const performSearch = async (query) => {
		navigate(`/search?q=${encodeURIComponent(query)}`)
	};

	return (
		<div className="search">
			<h1>TWO DEGREES</h1>
			<SearchBar onSearchCallback={performSearch}/>
		</div>
	)
};

export default Search;
